import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: "bg-grey-darken-4 pa-12",
    rounded: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "mx-auto px-6 py-8",
        "max-width": "344"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.form,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event)),
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.loginForm.Username,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginForm.Username) = $event)),
                readonly: _ctx.loading,
                rules: [_ctx.required],
                class: "mb-2",
                label: "Username",
                clearable: ""
              }, null, 8, ["modelValue", "readonly", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.loginForm.Password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm.Password) = $event)),
                readonly: _ctx.loading,
                rules: [_ctx.required],
                label: "Password",
                placeholder: "Enter your password",
                clearable: "",
                type: "Password"
              }, null, 8, ["modelValue", "readonly", "rules"]),
              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_v_btn, {
                disabled: !_ctx.form,
                loading: _ctx.loading,
                color: "success",
                size: "large",
                type: "submit",
                variant: "elevated",
                block: ""
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Sign In ")
                ])),
                _: 1
              }, 8, ["disabled", "loading"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}