<template>
    <v-container>
        <Line :data="chartJSGraph"></Line>
    </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SmartHomeService from '@/services/SmartHomeService';
import { createTypedChart, Line } from 'vue-chartjs';
import { CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import store from '@/store';
import { Queue } from '@/DataScructures/Queue';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default defineComponent({
    components: {
        Line
    },
    data() {
        return {
            chartJSGraph: {
                datasets: [] as any[],
                labels: [] as any[]
            },
            chartJSOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
        }
    },
    methods: {
    },
    async mounted() {

        const unsub = store.subscribe((mut, state) => {
            if (mut.type == "updateBeatValue") {
                let dataset = [];
                for (let index = 0; index < state.beatValues.length; index++) {
                    const value = state.beatValues.shift();
                    dataset.push(value);
                    if(value) {
                        state.beatValues.push(value);
                    }
                }
                this.chartJSGraph = {
                    datasets: [{
                        label: "Test",
                        backgroundColor: '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
                        data: dataset
                    }],
                    labels: dataset
                }
            }
        })

        store.dispatch("configureWebsockets");
    }
})
</script>