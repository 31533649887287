import { BaseWebSocket } from "@/models/BaseWebSocket"

export interface IWebsocketClient {
    CreateListener(wsEvents: Map<string, {(...Args: any[]): void}>): IWebsocketClient
    Connect(callback: { (...Args: any[]): void }): void
    SendMessage(request: BaseWebSocket): void
    Disconnect(): void
}

export class WebSocketClient implements IWebsocketClient {
    private socket!: WebSocket;
    private manualClose = false;
    private eventActions!: Map<string, { (...Args: any[]): void}>;
    private uri!: string
    private sendQueue: Map<string, BaseWebSocket> = new Map<string, BaseWebSocket>();

    constructor(uri: string, token: string | null) {
        const url = `${uri}?token=${token}`
        this.OpenSocket(url);
    }

    CreateListener(wsEvents: Map<string, {(...Args: any[]): void}>): this {
        this.eventActions = wsEvents;

        this.socket.onmessage = (me: MessageEvent) => {
            const data: BaseWebSocket = JSON.parse(me.data);

            const wsAction = wsEvents.get(data.EventType);

            if (wsAction) {
                wsAction(me, data);
            } else {
                console.log("Unexpected Event Type.")
            }
        }

        this.socket.onclose = () => {
            if(!this.manualClose) {
                console.log("Connection closed, re-connecting");
                this.Reconnect();
            }
        };

        return this;
    }

    SendMessage(request: BaseWebSocket): void {
        const uid = this.GetUID()
        this.sendQueue.set(uid, request);
        this.socket.send(JSON.stringify(request));
        setTimeout(() => {
            if(this.socket.readyState == WebSocket.OPEN) {
                this.sendQueue.delete(uid);
            }
        }, 20000)
    }

    Connect(callback: {(...Args: any[]): void}): void {
        this.socket.onopen = callback;
    }

    Disconnect(): void {
        this.manualClose = true;
        this.socket.close();
    }

    private OpenSocket(uri: string) {
        this.uri = uri;
        this.socket = new WebSocket(uri);
    }

    private Reconnect() {
        this.OpenSocket(this.uri);
        this.CreateListener(this.eventActions);
        this.socket.onopen = () => {
            this.ProcessQueue();
        }
    }

    private ProcessQueue() {
        const temp = new Map(this.sendQueue);
        temp.forEach((msg, key) => {
            this.sendQueue.delete(key);
            this.SendMessage(msg);
        })
    }

    private GetUID(): string {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
}