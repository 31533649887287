import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    class: "alerts-drawer-wrapper",
    name: "alerts-drawer-wrapper",
    tag: "div"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
        return (_openBlock(), _createBlock(_component_v_card, {
          key: error[0],
          class: "alerts-snack rounded-lg elevation-8",
          maxWidth: "500px",
          color: 'red',
          minWidth: '80%'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_actions, { class: "pl-4 pr-5" }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "grey--text text--lighten-3 body-1",
                  textContent: _toDisplayString(error[1].Error)
                }, null, 8, _hoisted_1),
                _createVNode(_component_v_icon, {
                  onClick: ($event: any) => (_ctx.popError(error[0])),
                  class: "pl-4",
                  icon: "fa fa-times"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}