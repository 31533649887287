import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-grey-darken-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorComponent = _resolveComponent("ErrorComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_ErrorComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}