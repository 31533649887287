import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { "no-gutters": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_sheet, {
                  style: {"width":"500px"},
                  class: "bg-grey-darken-4 pa-12",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      class: "mx-auto px-6 py-8",
                      "max-width": "344"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.message,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
                          label: "Message to Broadcast",
                          placeholder: "Broadcast Message",
                          clearable: "",
                          type: "text"
                        }, null, 8, ["modelValue"]),
                        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                        _createVNode(_component_v_btn, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.broadcastMessage())),
                          disabled: !_ctx.message,
                          color: "success",
                          size: "large",
                          variant: "elevated",
                          block: ""
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Send Message ")
                          ])),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_sheet, {
                  style: {"width":"700px"},
                  class: "bg-grey-darken-4 pa-12",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_table, { style: {"min-height":"150px"} }, {
                          default: _withCtx(() => [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", null, [
                                _cache[8] || (_cache[8] = _createElementVNode("th", { class: "text-left" }, "Name", -1)),
                                _cache[9] || (_cache[9] = _createElementVNode("th", { class: "text-left" }, "UID", -1)),
                                _cache[10] || (_cache[10] = _createElementVNode("th", { class: "text-left" }, "SteamID", -1)),
                                _createElementVNode("th", _hoisted_1, [
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.refreshPlayers())),
                                    "append-icon": "fas fa-sync-alt",
                                    color: "success",
                                    size: "small",
                                    variant: "elevated",
                                    block: ""
                                  }, {
                                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                                      _createTextVNode(" Refresh ")
                                    ])),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _createElementVNode("tbody", null, [
                              _withDirectives(_createElementVNode("tr", null, [
                                _createVNode(_component_v_progress_circular, {
                                  style: {"margin":"2rem"},
                                  color: "primary",
                                  indeterminate: ""
                                }),
                                _cache[11] || (_cache[11] = _createElementVNode("td", null, "Loading Players...", -1))
                              ], 512), [
                                [_vShow, _ctx.loading]
                              ]),
                              _withDirectives(_createElementVNode("tr", null, _cache[12] || (_cache[12] = [
                                _createElementVNode("td", null, "No players online.", -1)
                              ]), 512), [
                                [_vShow, _ctx.players?.length == 0 && !_ctx.loading]
                              ]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.players, (player) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: player.Name
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(player.Name), 1),
                                  _createElementVNode("td", null, _toDisplayString(player.UID), 1),
                                  _createElementVNode("td", null, _toDisplayString(player.SteamID), 1),
                                  _cache[13] || (_cache[13] = _createElementVNode("td", null, [
                                    _createElementVNode("button", { style: {"color":"red"} }, "Kick Me")
                                  ], -1))
                                ]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_sheet, {
                  style: {"width":"500px"},
                  class: "bg-grey-darken-4 pa-12",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveAndRestart())),
                          "append-icon": "fas fa-save",
                          color: "success",
                          size: "large",
                          variant: "elevated",
                          block: ""
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Save and Restart Server ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_sheet, {
                  style: {"width":"500px"},
                  class: "bg-grey-darken-4 pa-12",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                      default: _withCtx(() => [
                        _cache[16] || (_cache[16] = _createElementVNode("label", null, "Server Status:", -1)),
                        _createVNode(_component_v_sheet, {
                          color: _ctx.serverStatus == 'UP' ? 'success' : 'error',
                          elevation: 3,
                          style: {"margin-bottom":"2rem"},
                          class: "mx-auto px-6 py-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.serverStatus), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createVNode(_component_v_btn, {
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getStatus())),
                          "append-icon": "fas fa-sync-alt",
                          color: "info",
                          size: "large",
                          variant: "elevated",
                          block: ""
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode(" Refresh ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}