import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_PalWorldDashboard = _resolveComponent("PalWorldDashboard")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_SmartHomeDashboard = _resolveComponent("SmartHomeDashboard")!
  const _component_TestComponent = _resolveComponent("TestComponent")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "bg-grey-darken-4" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        "align-tabs": "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, {
            text: "Palworld RCON",
            value: "tab-1"
          }),
          _createVNode(_component_v_tab, {
            text: "Smart Home",
            value: "tab-2"
          }),
          _createVNode(_component_v_tab, {
            text: "Test Area",
            value: "tab-3"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_window, {
        modelValue: _ctx.tab,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_window_item, { value: "tab-1" }, {
            default: _withCtx(() => [
              _createVNode(_component_PalWorldDashboard)
            ]),
            _: 1
          }),
          _createVNode(_component_v_window_item, { value: "tab-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_SmartHomeDashboard)
            ]),
            _: 1
          }),
          _createVNode(_component_v_window_item, { value: "tab-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_TestComponent)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}